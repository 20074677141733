import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueMeta from 'vue-meta'
import jquery from 'jquery'
import {gsap, ScrollTrigger, ScrollToPlugin} from 'gsap/all'

Vue.config.productionTip = false

Vue.use(VueMeta)

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
window.gsap = gsap
window.ScrollTrigger = ScrollTrigger

window.$ = jquery

$(window).on("resize", function (){
  if ($(this).width() > 1600) {
    if (window.device == 'mobile' || window.device == 'laptop') {
      location.reload()
    }
    window.device = 'desktop';
  }else if($(this).width() > 1025){
    if (window.device == 'mobile' || window.device == 'desktop') {
      location.reload()
    }
    window.device = 'laptop';
  }else{
    if (window.device == 'desktop' || window.device == 'laptop') {
      location.reload()
    }
    window.device = 'mobile';
  }
}).trigger("resize");


new Vue({
  store,
  router,
  render: h => h(App),
  mounted() {
		// setTimeout(() => {
		// 	document.dispatchEvent(new Event("x-app-rendered"));
		// }, 5000);
  },
}).$mount('#app')
