<template>
  <footer class="footerWrap">
    <div class="innerWrap grid-x align-center-middle">
      <div class="logo cell large-shrink"><a href="./"><img src="../assets/footer-logo.svg"></a></div>

      <div class="looking-area cell large-shrink"><a href="mailto:easing.stretchstudio@gmail.com"><img src="../assets/footer-looking.svg"></a></div>

      <ul class="info-area cell large-shrink">
        <li class="grid-x align-middle">
          <div class="title cell shrink">ADDRESS</div>
          <div class="content cell auto"><a href="https://goo.gl/maps/h4d9JEMb22u8WfpD6" target="_blank">台中市南屯區河南路四段261號 <img class="show-for-large" src="../assets/map-link.svg"></a></div>
        </li>
        <li class="grid-x align-middle">
          <div class="title cell shrink">OPEN HOURS</div>
          <div class="content cell auto">週ㄧ~週日 <i>10:00~22:00</i></div>
        </li>
        <li class="grid-x align-middle">
          <div class="title cell shrink">TEL</div>
          <div class="content cell auto"><a href="tel:+886422520558"><i>04-2252-0558</i></a></div>
        </li>
        <li class="grid-x align-middle">
          <div class="title cell shrink">FOLLOW US</div>
          <nav class="shareList cell auto">
            <a href="https://page.line.me/729illup?openQrModal=true" target="_blank"><img src="../assets/line.svg"></a>
            <a href="https://www.facebook.com/EasingStretchStudio/" target="_blank"><img src="../assets/fb.svg"></a>
            <a href="https://www.instagram.com/easing.ss/" target="_blank"><img src="../assets/ig.svg"></a>
          </nav>
        </li>
      </ul>
    </div>

    <div class="bot-area grid-x align-center-middle">
      <div class="note cell large-shrink">本工作室不涉及任何醫療行為，不宣稱任何療效。如須尋求專業醫療診斷、建議或治療，請向醫師或其他專業醫護人員諮詢。</div>
      <!-- <div class="copyright cell large-shrink">@易筋 STRETCH STUDIO ALL RIGHT RESERVED. <br class="hide-for-large">SITE BY <a href="https://www.goods-design.com.tw/" target="_blank">很好設計 GOODS DESIGN.</a></div> -->
    </div>
  </footer>
</template>

<script>
  export default {
    props: {},
    mounted() {}
  }
</script>

<style scoped lang="scss">
  .footerWrap{
    padding: 87px 0 34px;
    @include breakpoint(medium down){
      padding: 50px 20px 30px;
    }
  }
  .innerWrap{
    .logo{
      margin-right: 130px;
      @include breakpoint(xlarge down){
        margin-right: 100px;
        img{zoom: 0.7;}
      }
      @include breakpoint(medium down){
        text-align: center;
        margin-right: 0;
        margin-bottom: 80px;
      }
    }
    .looking-area{
      margin-right: 134px;
      @include basic-hover;
      @include breakpoint(xlarge down){
        margin-right: 90px;
        img{zoom: 0.7;}
      }
      @include breakpoint(medium down){
        text-align: center;
        margin-right: 0;
        margin-bottom: 80px;
      }
    }
    .info-area{
      li{
        @include mb(22px);
        @include breakpoint(xlarge down){
          @include mb(10px);
        }
        @include breakpoint(medium down){
          @include flex-align(left, top);
        }
      }
      .title{
        font-family: $en-family;
        font-weight: 500;
        font-style: italic;
        font-size: 22px;
        letter-spacing: 4px;
        color: #606060;
        width: 208px;
        @include breakpoint(xlarge down){
          font-size: 16px;
          letter-spacing: 3px;
          width: 142px;
        }
        @include breakpoint(medium down){
          font-size: 12px;
          letter-spacing: 2px;
          width: 104px;
          position: relative;
          top: 5px;
        }
      }
      .content{
        font-family: $content-family;
        font-size: 22px;
        letter-spacing: 3px;
        line-height: 1.5;
        color: #606060;
        @include breakpoint(xlarge down){
          font-size: 16px;
          letter-spacing: 2px;
          img{zoom: 0.7;}
        }
        @include breakpoint(medium down){
          font-size: 15px;
          padding-left: 12px;
          position: relative;
          &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 3px;
            bottom: 3px;
            width: 1px;
            background-color: #606060;
          }
        }
        i{
          font-style: italic;
        }
        img{
          position: relative;
          top: -2px;
          margin-left: 2px;
        }
      }
      .shareList{
        a{
          @include basic-hover;
          @include mr(35px);
          @include breakpoint(xlarge down){
            @include mr(25px);
          }
        }
      }
    }
  }
  .bot-area{
    margin-top: 90px;
    opacity: .5;
    @include breakpoint(xlarge down){
      margin-top: 70px;
    }
    @include breakpoint(medium down){
      margin-top: 45px;
    }
    .note{
      font-family: $content-family;
      font-size: 15px;
      letter-spacing: 3px;
      line-height: 1.5;
      color: #606060;
      margin-right: 50px;
      @include breakpoint(xlarge down){
        font-size: 12px;
        letter-spacing: 1px;
        margin-right: 12px;
      }
      @include breakpoint(medium down){
        margin-right: 0;
        margin-bottom: 22px;
      }
    }
    .copyright{
      font-family: $content-family;
      font-size: 15px;
      letter-spacing: 1px;
      line-height: 1.5;
      color: #606060;
      @include breakpoint(xlarge down){
        font-size: 12px;
        letter-spacing: 0;
      }
    }
  }
</style>