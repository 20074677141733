<template>
  <div>
    <div ref="el">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['p', 'st', 'm'],
    data() {
      return {}
    },
    methods: {},
    mounted() {
      var d1 = {
        duration: 0.5,
        scaleX: 1.4,
        ease: 'power2.in',
      }

      var d2 = {
        duration: 1,
        scaleX: 1,
        ease: 'elastic.out(1, 0.4)',
      }

      var ani1 = Object.assign(d1, this.p)
      var ani2 = Object.assign(d2, this.p)

      if (device == 'mobile' && this.m != undefined) {
        ani1 = Object.assign(ani1, this.m)
        ani2 = Object.assign(ani2, this.m)
      }

      gsap.timeline({
        scrollTrigger: {
          trigger: this.$refs.el.parentElement,
          start: "top 80%",
          end: "bottom 0%",
          toggleActions: "play none play none",
          // markers: true
        },
      }).to(this.$refs.el, ani1).to(this.$refs.el, ani2)
    },
  }
</script>

<style scoped lang="scss"></style>