<template>
  <header>
    <div class="fix-logo"><a href="./">
      <img src="../assets/logo.svg" class="show-for-large">
      <img src="../assets/logo-mobile.svg" class="hide-for-large">
    </a></div>

    <ul class="menuList show-for-large">
      <li data-go=".benefitOutWrap">ADVANTAGE</li>
      <li data-go=".serviceWrap">SERVICE</li>
      <li data-go=".aboutWrap">ABOUT</li>
      <li data-go=".spaceWrap">SPACE</li>
      <li data-go=".reservationWrap">RESERVATION</li>
    </ul>

    <div class="now-area show-for-large"><a href="https://page.line.me/729illup?openQrModal=true" target="_blank">
      <div class="now"><img src="../assets/btn-now.svg"></div>
      <div class="rotate-text">
        <svg xmlns="http://www.w3.org/2000/svg" width="109" height="100.99" viewBox="0 0 109 100.99">
          <defs>
            <path id="path-1" d="M73.69-4.28c19.15,6.75,28.71,27.06,24.21,50-6.1,31.2-33.29,49.27-68.15,45.49C4.61,88.38-9,73.16-9,48-9.46,3.33,34.92-17.84,73.69-4.28ZM73.69-4.28c19.15,6.75,28.71,27.06,24.21,50-6.1,31.2-33.29,49.27-68.15,45.49C4.61,88.38-9,73.16-9,48-9.46,3.33,34.92-17.84,73.69-4.28Z" transform="translate(9.5 8.84)" style="fill: none;stroke: #000;stroke-miterlimit: 10" />
          </defs>

          <text>
            <textPath class="rydertext" xlink:href="#path-1">RESERVE NOW!</textPath>
          </text>
        </svg>
      </div>
    </a></div>

    <div class="hamburger hide-for-large">
      <span class="item1"></span>
      <span class="item2"></span>
      <span class="item3"></span>
    </div>

    <div class="mobile-openWrap hide-for-large">
      <div class="colorbox"></div>

      <ul class="mobile-menuList">
        <li data-go=".benefitOutWrap">
          <div class="en">ADVANTAGE</div>
          <div class="ch">筋膜伸展的好處</div>
        </li>
        <li data-go=".aboutWrap">
          <div class="en">ABOUT</div>
          <div class="ch">關於易筋</div>
        </li>
        <li data-go=".serviceWrap">
          <div class="en">SERVICE</div>
          <div class="ch">服務項目</div>
        </li>
        <li data-go=".spaceWrap">
          <div class="en">SPACE</div>
          <div class="ch">空間資訊</div>
        </li>
        <li data-go=".reservationWrap">
          <div class="en">RESERVATION</div>
          <div class="ch">立即預約</div>
        </li>
      </ul>

      <div class="info-area">
        <a href="https://www.google.com/maps/place/408%E5%8F%B0%E4%B8%AD%E5%B8%82%E5%8D%97%E5%B1%AF%E5%8D%80%E6%B2%B3%E5%8D%97%E8%B7%AF%E5%9B%9B%E6%AE%B5261%E8%99%9F/@24.1544133,120.6347023,17z/data=!3m1!4b1!4m5!3m4!1s0x34693de97895b785:0x1e4e461604b01998!8m2!3d24.1544133!4d120.636891" target="_blank">台中市南屯區河南路四段261號</a><br>
        週ㄧ~週日 <i>10:00~22:00</i><br>
        <a href="tel:+886422520558"><i>04-2252-0558</i></a><br>
      </div>

      <nav class="shareList">
        <a href="https://www.facebook.com/EasingStretchStudio/" target="_blank"><img src="../assets/fb-green.svg"></a>
        <a href="https://www.instagram.com/easing.ss/" target="_blank"><img src="../assets/ig-green.svg"></a>
        <a href="https://page.line.me/729illup?openQrModal=true" target="_blank"><img src="../assets/line-green.svg"></a>
      </nav>

      <div class="mobile-now-area hide-for-large"><a href="https://page.line.me/729illup?openQrModal=true" target="_blank">
        <div class="now"><img src="../assets/btn-now.svg"></div>
        <div class="rotate-text">
          <svg xmlns="http://www.w3.org/2000/svg" width="109" height="100.99" viewBox="0 0 109 100.99">
            <defs>
              <path id="path-2" d="M73.69-4.28c19.15,6.75,28.71,27.06,24.21,50-6.1,31.2-33.29,49.27-68.15,45.49C4.61,88.38-9,73.16-9,48-9.46,3.33,34.92-17.84,73.69-4.28ZM73.69-4.28c19.15,6.75,28.71,27.06,24.21,50-6.1,31.2-33.29,49.27-68.15,45.49C4.61,88.38-9,73.16-9,48-9.46,3.33,34.92-17.84,73.69-4.28Z" transform="translate(9.5 8.84)" style="fill: none;stroke: #000;stroke-miterlimit: 10" />
            </defs>

            <text>
              <textPath class="rydertext" xlink:href="#path-2">RESERVE NOW!</textPath>
            </text>
          </svg>
        </div>
      </a></div>
    </div>
  </header>
</template>

<script>
  export default {
    props: {},
    mounted() {
      gsap.to("header .rydertext", {
        duration: 12,
        repeat: -1,
        attr: {
          startOffset: "50%"
        },
        ease: "none",
      })




      $("[data-go]").on("click", function () {
        gsap.to(window, {
          duration: 1.2,
          scrollTo: this.dataset.go,
          ease: 'power2.inOut',
        });

        // mobile
        $(".mobile-openWrap").removeClass("is-show")
        $(".hamburger").removeClass("is-show")
        $mobile.timeScale(2)
        $mobile.reverse()
      })




      var $mobile = gsap.timeline({
        paused: true
      }).from(".mobile-openWrap .colorbox", {
        duration: .8,
        yPercent: -100,
        ease: 'power2.inOut'
      }).from(".mobile-menuList li", {
        duration: 1.2,
        opacity: 0,
        x: -30,
        stagger: .12,
      }, "<.4").from(".mobile-openWrap .info-area", {
        duration: 1.2,
        opacity: 0,
        x: -30,
      }, "<.2").from(".mobile-openWrap .shareList", {
        duration: 1.2,
        opacity: 0,
        x: -30,
      }, "<.2").from(".mobile-now-area", {
        duration: 1.2,
        opacity: 0,
        y: 30,
      }, "<.2")


      $(".hamburger").on("click", function () {
        if (!$(this).hasClass("is-show")) {
          $(".mobile-openWrap").addClass("is-show")
          $(this).addClass("is-show")
          $mobile.timeScale(1)
          $mobile.play()
        }else{
          $(".mobile-openWrap").removeClass("is-show")
          $(this).removeClass("is-show")
          $mobile.timeScale(2)
          $mobile.reverse()
        }

      })
    }
  }
</script>

<style scoped lang="scss">
  .fix-logo{
    z-index: 49;
    position: fixed;
    top: 50px;
    left: 60px;
    @include breakpoint(xlarge down){
      top: 40px;
      left: 40px;
      img{height: 38px;}
    }
    @include breakpoint(medium down){
      top: 20px;
      left: 20px;
      img{height: auto;}
    }
  }
  .menuList{
    z-index: 49;
    position: fixed;
    top: 55px;
    right: 60px;
    @include breakpoint(xlarge down){
      top: 40px;
      right: 40px;
    }
    li{
      font-family: $en-family;
      font-size: 20px;
      letter-spacing: 2px;
      color: #606060;
      writing-mode: vertical-rl;
      @include basic-hover;
      @include mb(33px);
      @include breakpoint(xlarge down){
        font-size: 15px;
        letter-spacing: 2px;
        @include mb(24px);
      }
    }
  }
  .now-area{
    z-index: 49;
    position: fixed;
    right: 50px;
    bottom: 40px;
    @include basic-hover;
    @include breakpoint(xlarge down){
      right: 93px;
      bottom: 30px;
      zoom: 0.78;
    }
    .now{
      img{zoom: 0.95;}
    }
    .rotate-text{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 1px 0 0 0;
      svg{
        overflow: visible;
        .rydertext{
          font-family: $en-family;
          font-weight: 500;
          font-size: 13px;
          letter-spacing: 2px;
          fill: #969696;
        }
      }
    }
  }
  .hamburger{
    z-index: 49;
    width: 40px;
    height: 15px;
    position: fixed;
    top: 26px;
    right: 20px;
    cursor: pointer;
    &.is-show{
      .item1{
        top: 50%;
        width: 25px;
        transform: rotate(30deg);
      }
      .item2{
        opacity: 0;
      }
      .item3{
        top: 50%;
        transform: rotate(-25deg);
      }
    }
    span{
      display: block;
      width: 33px;
      height: 2px;
      background-color: #009F47;
    }
    .item1{
      position: absolute;
      top: 0%;
      left: 6px;
      transform: rotate(0deg);
      transition: all .3s;
    }
    .item2{
      position: absolute;
      top: 50%;
      left: 3px;
      transition: all .3s;
    }
    .item3{
      position: absolute;
      top: 100%;
      transform: rotate(0deg);
      transition: all .3s;
    }
  }
  .mobile-openWrap{
    z-index: 48;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 95px 40px 50px;
    overflow-y: auto;
    pointer-events: none;
    &.is-show{
      pointer-events: all;
    }
    .colorbox{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #EFEFED;
    }
    .mobile-menuList{
      li{
        cursor: pointer;
        @include mb(30px);
      }
      .en{
        font-family: $en-family;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 1px;
        color: #606060;
        margin-bottom: 10px;
      }
      .ch{
        font-family: $content-family;
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 6px;
        color: #606060;
      }
    }
    .info-area{
      font-family: $content-family;
      font-size: 13px;
      letter-spacing: 3px;
      line-height: 2.2;
      color: #606060;
      margin-top: 60px;
      i{
        font-weight: 500;
        font-family: $en-family;
        font-style: italic;
      }
    }
    .shareList{
      margin-top: 30px;
      a{
        @include mr(27px);
      }
    }
    .mobile-now-area{
      position: absolute;
      right: 30px;
      bottom: 20px;
      zoom: 0.65;
      .now{}
      .rotate-text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 1px 0 0 0;
        svg{
          overflow: visible;
          .rydertext{
            font-family: $en-family;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 2px;
            fill: #969696;
          }
        }
      }
    }
  }
</style>