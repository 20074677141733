<template>
  <div class="spaceWrap">
    <div class="deco-area">
      <div class="wave-top"></div>
      <div class="wave-bot"></div>
    </div>

    <div class="head-area">
      <RyderStretch :p="{
        transformOrigin: 'left',
      }" :m="{
        transformOrigin: '50% 50%',
      }" class="en">SPACE</RyderStretch>
      <div class="ch">空間資訊</div>
    </div>

    <div class="space-marquee-outWrap">
      <!-- <div class="deco"><img src="../assets/space-deco.svg"></div> -->

      <div class="space-sliderWrap">
        <flickity class="space-sliderList" ref="flickity" :options="flickityOptions">
          <div class="carousel-cell"><img src="../assets/space-1.jpg"></div>
          <div class="carousel-cell"><img src="../assets/space-2.jpg"></div>
          <div class="carousel-cell"><img src="../assets/space-3.jpg"></div>
          <div class="carousel-cell"><img src="../assets/space-4.jpg"></div>
          <div class="carousel-cell"><img src="../assets/space-5.jpg"></div>
          <div class="carousel-cell"><img src="../assets/space-6.jpg"></div>
          <div class="carousel-cell"><img src="../assets/space-7.jpg"></div>
          <div class="carousel-cell"><img src="../assets/space-8.jpg"></div>
        </flickity>

        <div class="prev" @click="previous()"><img src="../assets/space-prev.svg"></div>
        <div class="next" @click="next()"><img src="../assets/space-next.svg"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import RyderStretch from './RyderStretch.vue'
  import Flickity from 'vue-flickity';

  export default {
    data() {
      return {
        flickityOptions: {
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
          imagesLoaded: true,
          autoPlay: 4000,
        }
      }
    },
    components: {
      RyderStretch,
      Flickity,
    },
    props: {},
    computed: {},
    methods: {
      next() {
        this.$refs.flickity.next();
      },
      previous() {
        this.$refs.flickity.previous();
      }
    },
    mounted() {}
  }
</script>

<style scoped lang="scss">
  .spaceWrap{
    position: relative;
    margin-top: 280px;
    @include breakpoint(xlarge down){
      margin-top: 170px;
    }
    @include breakpoint(medium down){
      margin-top: 130px;
    }
    .head-area{
      padding: 0 var(--space);
      @include breakpoint(medium down){
        text-align: center;
      }
      .en{
        @include m-head-en;
      }
      .ch{
        @include m-head-ch;
        margin-top: 8px;
      }
    }
    .deco-area{
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 784px;
      background-color: rgba(#EFEFED, .5);
      @include breakpoint(xlarge down){
        height: 560px;
      }
      @include breakpoint(medium down){
        height: 310px;
      }
      .wave-top{
        @include m-wave-top;
      }
      .wave-bot{
        @include m-wave-bot;
      }
    }
  }
  .space-marquee-outWrap{
    position: relative;
    margin-top: 60px;
    .deco{
      position: absolute;
      right: 18.33%;
      bottom: -190px;
      z-index: 4;
      @include breakpoint(xlarge down){
        bottom: -121px;
        img{zoom: 0.66;}
      }
      @include breakpoint(medium down){
        right: 13.33%;
        bottom: -68px;
        img{zoom: 0.35;}
      }
    }
  }
  .space-sliderWrap{
    position: relative;
    .space-sliderList{
      .carousel-cell{
        width: 976px;
        height: 585px;
        margin: 0 78px;
        img{@include imgfit;}
        @include breakpoint(xlarge down){
          width: 650px;
          height: 390px;
          margin: 0 52px;
        }
        @include breakpoint(medium down){
          width: 100%;
          height: 252px;
        }
      }
    }
    .prev, .next{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: -567px;
      @include basic-hover;
      @include breakpoint(xlarge down){
        margin-left: -376px;
        img{width: 62px;}
      }
    }
    .next{
      margin-left: 567px;
      @include breakpoint(xlarge down){
        margin-left: 376px;
      }
    }
  }
</style>