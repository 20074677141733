<template>
  <div class="mainWrap">
    <!-- <Sticky></Sticky> -->
    <Index></Index>
    <Benefit></Benefit>
    <About></About>
    <Service></Service>
    <Space></Space>
    <Reservation></Reservation>
  </div>
</template>

<script>
// import Sticky from '../components/Sticky.vue'
import Index from '../components/Index.vue'
import Benefit from '../components/Benefit.vue'
import About from '../components/About.vue'
import Service from '../components/Service.vue'
import Space from '../components/Space.vue'
import Reservation from '../components/Reservation.vue'


export default {
  components: {
    // Sticky,
    Index,
    Benefit,
    About,
    Service,
    Space,
    Reservation,
  }
}
</script>

<style lang="scss">
  .mainWrap{
    // overflow-x: hidden;
  }
</style>