<template>
  <div class="loadingWrap">
    <div class="stretch-area grid-y align-justify">
      <Stretch class="cell shrink"></Stretch>
      <Stretch class="cell shrink"></Stretch>
      <Stretch class="cell shrink"></Stretch>
      <Stretch class="cell shrink"></Stretch>
      <Stretch class="cell shrink"></Stretch>
    </div>
    <div class="num">
      <i>
        <Load
          :endVal="100"
          :options="options"
          @ready="loadReady"
        />
      </i>
    %</div>
  </div>
</template>

<script>
  import Load from 'vue-countup-v2';
  import Stretch from './Stretch.vue';

  export default {
    components: {
      Load,
      Stretch,
    },
    props: {},
    data() {
      return {
        $load: null,
        options: {
          duration: 2,
          separator: '',
        }
      }
    },
    methods: {
      loadReady: function(instance, CountUp) {
        this.$load = instance
      },
    },
    mounted() {
      var $stretch = $(".stretch-area").children()
      gsap.set($stretch, {
        x: "random(-100, -900)",
      })

      $(".loadingWrap").delay(1600).fadeOut(1000)
    },
  }
</script>

<style scoped lang="scss">
  .loadingWrap{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 199;
    background-color: #fff;
    .stretch-area{
      position: absolute;
      top: -40px;
      right: 0;
      bottom: -40px;
      left: 0;
      opacity: .1;
    }
    .num{
      font-family: $en-family;
      font-weight: 700;
      font-size: 56px;
      color: #009f47;
      white-space: nowrap;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include breakpoint(xlarge down){
        font-size: 48px;
      }
      @include breakpoint(medium down){
        font-size: 34px;
      }
      i{
        font-style: italic;
        font-size: 131px;
        margin-right: 18px;
        position: relative;
        top: 4px;
        @include breakpoint(xlarge down){
          font-size: 112px;
          margin-right: 10px;
        }
        @include breakpoint(medium down){
          font-size: 78px;
          margin-right: 2px;
        }
      }
    }
  }
</style>