import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import('@/views/About.vue')
  // }, {
  //   path: '/news',
  //   name: 'News',
  //   component: () => import('@/views/News.vue')
  // }, {
  //   path: '/news/:id',
  //   name: 'NewsDetail',
  //   component: () => import('@/views/NewsDetail.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   router.app.$store.state.loading = true
//   gsap.delayedCall(1, next);
// })

// router.afterEach((to, from) => {
//   router.app.$store.state.loading = false
// })

export default router
