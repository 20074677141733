import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  	loading: true,
    count: 0,
    hover: false
  },
  mutations: {
		backtotop() {
			gsap.to(window, {
				duration: 1.2,
				scrollTo: 0,
				ease: 'power2.inOut',
			});
		},
    scrolldown() {
      gsap.to(window, {
        duration: 1.2,
        scrollTo: $(window).height(),
        ease: 'power2.inOut',
      });
    },
    cursorShow() {
      gsap.to(".cursor-area", {
        duration: 0.5,
        scale: 1,
      });
    },
    cursorHide() {
      gsap.to(".cursor-area", {
        duration: 0.5,
        scale: 0,
      });
    },
  },
  actions: {
  },
  modules: {
  }
})
