<template>
  <div class="ryder-cursor show-for-large">
    <div class="cursor-area"><img src="../assets/cursor.svg"></div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {}
    },
    methods: {},
    mounted() {
      function RyderCursor(el) {
        this.$el = el
        this.x = 0
        this.y = 0
        this.mouseX = 0
        this.mouseY = 0
        this.radians = 0
        this.easing = 0.1

        this.init = function () {


          gsap.ticker.add(this.update.bind(this));


          $(window).on("mousemove", (e) => {
            this.mouseX = e.clientX - this.$el.width() / 2
            this.mouseY = e.clientY - this.$el.height() / 2
          })

        }

        this.update = function () {

          var dx = this.mouseX - this.x
          this.x += dx * this.easing


          var dy = this.mouseY - this.y
          this.y += dy * this.easing



          var speedX = Math.abs(this.mouseX - this.x)
          var speedY = Math.abs(this.mouseY - this.y)
          var speed = Math.sqrt(speedX * speedX + speedY * speedY) * -1

          if (speed > -1) {
            speed = 0;
          }

          var scale = speed / 500 + 1;

          if (scale < 0.2) {
            scale = 0.2;
          }


          var angle = Math.atan2(this.mouseX - this.x, -(this.mouseY - this.y)) * (180 / Math.PI);



          // gsap.set(this.$el, {
          //   rotation: angle,
          // })

          gsap.to(this.$el, .1, {
            x: this.x,
            y: this.y,
            // scaleX: scale,
          })

        }
      }


      new RyderCursor($(".ryder-cursor")).init();



      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);


      if (isTablet) {
        $(".ryder-cursor").hide()
      }
    },
  }
</script>

<style scoped lang="scss">
  .ryder-cursor{
    z-index: 71;
    position: fixed;
    top: 0;
    left: 0;
    width: 124px;
    height: 115px;
    pointer-events: none;
    @include breakpoint(xlarge down){
      width: 87px;
      height: 81px;
    }
    .cursor-area{
      transform: scale(0);
    }
  }
</style>