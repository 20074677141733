<template>
  <section class="index-outWrap">
    <div class="indexWrap">
      <div class="deco-egg">
        <img src="../assets/deco-egg.png" class="show-for-large">
        <img src="../assets/deco-egg-mobile.png" class="hide-for-large">
      </div>

      <div class="biglogo-area">
        <div class="logo"><img src="../assets/biglogo.svg"></div>
        <div class="curve"><img src="../assets/curve-slogan.svg"></div>
        <!-- <div class="handwriting">
          <img class="invisible" src="../assets/handwriting.svg">
          <Lottie class="lottie-el"
            :options="aniOption"
            @animCreated="handleAnimation"/>
        </div> -->
        <!-- <div class="text">專業筋膜伸展</div> -->
      </div>

      <div class="scrolldown show-for-large" @click="$store.commit('scrolldown')"><img src="../assets/scrolldown.svg"></div>

      <div class="wave-bot"></div>
    </div>

    <div class="maskWrap">
      <div class="mask-debug flex-container align-center align-top"><img @load="setMaskSize" src="../assets/mask.svg"></div>
      <div class="pic"><img src="../assets/mask-pic.jpg"></div>

      <div class="title-area show-for-large">
        <div class="en">ADVANTAGE</div>
        <div class="ch">筋膜伸展的好處</div>
      </div>
    </div>

    <div class="mobile-title-area hide-for-large">
      <div class="en">ADVANTAGE</div>
      <div class="ch">筋膜伸展的好處</div>
    </div>

    <div class="wave-bot2"></div>
  </section>
</template>

<script>
  // import Lottie from 'vue-lottie'
  import aniData from '../assets/ae/data.json'
  import vhCheck from 'vh-check'

  export default {
    components: {
      // Lottie
    },
    props: {},
    data() {
      return {
        aniOption: {
          animationData: aniData,
          loop: false,
          autoplay: false,
        },
      }
    },
    methods: {
      handleAnimation: function(anim) {
        this.anim = anim;
      },
      setMaskSize() {
        var $mask = $(".mask-debug img")
        var _s = $mask.get(0).getBoundingClientRect()
        var _t = $mask.offset().top - $mask.parent().offset().top

        $(".maskWrap .pic").css({
          "-webkit-mask-size": _s.width,
          "-webkit-mask-position-x": _s.left,
          "-webkit-mask-position-y": _t,
        })
      },
    },
    mounted() {
      // var $handwriting = lottie.loadAnimation({
      //   container: $("#app").get(0),
      //   renderer: 'svg',
      //   loop: true,
      //   autoplay: true,
      //   path: aniData
      // });
      var _vhCheck = vhCheck({
        bind: false,
        redefineVh: true,
      });





      var $enter = gsap.timeline({
        delay: 1.5,
      }).from(".deco-egg img", {
        duration: 1.2,
        opacity: 0,
        y: (device == 'mobile') ? -30 : -60,
      }).from(".indexWrap .logo", {
        duration: 1.2,
        opacity: 0,
        y: (device == 'mobile') ? 30 : 60,
      }, "<.4").from(".indexWrap .text", {
        duration: 1.2,
        opacity: 0,
        y: (device == 'mobile') ? 30 : 60,
        onComplete: () => {
          // this.anim.play()
        },
      }, "<.4").from(".indexWrap .scrolldown", {
        duration: 1.2,
        opacity: 0,
        y: (device == 'mobile') ? -30 : -60,
      }, "<.4").from(".maskWrap .pic", {
        duration: 1.2,
        opacity: 0,
        y: (device == 'mobile') ? 30 : 60,
      }, "<.4")





      var $title = gsap.timeline({
        paused: true
      }).from(".maskWrap .title-area", {
        duration: 0.5,
        opacity: 0,
      }).to(".maskWrap .title-area .en", {
        duration: 0.5,
        scaleX: 1.4,
        transformOrigin: 'right',
        ease: 'power2.in',
      }, "<").to(".maskWrap .title-area .en", {
        duration: 1,
        scaleX: 1,
        transformOrigin: 'right',
        ease: 'elastic.out(1, 0.4)',
      })

      var $mobile_title = gsap.timeline({
        paused: true
      }).from(".index-outWrap .mobile-title-area", {
        duration: 0.5,
        opacity: 0,
      }).to(".index-outWrap .mobile-title-area .en", {
        duration: 0.5,
        scaleX: 1.4,
        ease: 'power2.in',
      }, "<").to(".index-outWrap .mobile-title-area .en", {
        duration: 1,
        scaleX: 1,
        ease: 'elastic.out(1, 0.4)',
      })


      var _enter = false


      if (device != 'mobile') {
        let tl_mask = gsap.timeline({
          scrollTrigger: {
            trigger: ".maskWrap",
            toggleActions: "play pause resume reverse",
            start: "center 50%",
            end: "+=200%",
            pin: ".maskWrap",
            scrub: true,
            // markers: true,
          },
        }).to(".mask-debug img", {
          duration: 2,
          scale: 3,
          onUpdate: () => {
            this.setMaskSize()
            if (tl_mask.progress() > 0.9 && !_enter) {
              _enter = true
              $title.play()
              $mobile_title.play()
            }
          }
        })
        // 調圖片位置…問題有點多
        // .to(".maskWrap .pic img", {
        //   duration: 2,
        //   width: 2646,
        //   x: -404,
        //   y: -763,
        //   ease: 'none',
        // }, "<")
      }else{
        let tl_mask = gsap.timeline({
          scrollTrigger: {
            trigger: ".maskWrap",
            toggleActions: "play pause resume reverse",
            start: "center 60%",
            end: "100",
            scrub: true,
            // markers: true,
          },
        }).to(".mask-debug img", {
          duration: 2,
          scale: 1.8,
          onUpdate: () => {
            this.setMaskSize()
            if (tl_mask.progress() > 0.9 && !_enter) {
              _enter = true
              $title.play()
              $mobile_title.play()
            }
          }
        })
      }


    },
  }
</script>

<style scoped lang="scss">
  .index-outWrap{
    position: relative;
    .wave-bot2{
      @include m-wave-bot;
      z-index: 9;
    }
  }
  .indexWrap{
    position: relative;
    background-color: rgba(#EFEFED, .5);
    padding: 206px 0 250px;
    @include breakpoint(xlarge down){
      padding: 153px 0 348px;
    }
    @include breakpoint(medium down){
      padding-top: 138px;
    }
    .deco-egg{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      @include breakpoint(medium down){
        img{max-width: initial}
      }
    }
    .biglogo-area{
      z-index: 1;
      position: relative;
      text-align: center;
      .logo{
        margin-bottom: 20px;
        @include breakpoint(xlarge down){
          img{zoom: 0.67;}
        }
        @include breakpoint(medium down){
          margin-bottom: 10px;
          img{zoom: 0.3;}
        }
      }
      .curve{
        margin-top: 100px;
        position: relative;
        left: 18px;
        @include breakpoint(xlarge down){
          left: 12px;
          img{zoom: 0.67;}
        }
        @include breakpoint(medium down){
          left: 8px;
          margin-top: 50px;
          img{zoom: 0.36;}
        }
      }
      // .handwriting{
      //   position: relative;
      //   margin-bottom: 30px;
      //   @include breakpoint(xlarge down){
      //     img{height: 80px;}
      //   }
      //   @include breakpoint(medium down){
      //     margin-bottom: 16px;
      //     img{height: 40px;}
      //   }
      //   .lottie-el{
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     width: 100%;
      //     height: 100%;
      //   }
      // }
      // .text{
      //   font-family: $content-family;
      //   font-size: 35px;
      //   letter-spacing: 23px;
      //   color: #606060;
      //   @include breakpoint(xlarge down){
      //     font-size: 23px;
      //     letter-spacing: 15px;
      //   }
      //   @include breakpoint(medium down){
      //     font-size: 12px;
      //     letter-spacing: 8px;
      //   }
      // }
    }
    .scrolldown{
      position: absolute;
      top: 66%;
      left: 11%;
      z-index: 11;
      img{@include basic-hover;}
      @include breakpoint(xlarge down){
        img{width: 54px;}
      }
      @include breakpoint(medium down){
        position: relative;
        top: 0;
        left: 0;
        text-align: center;
        margin-top: 40px;
        img{width: 45px;}
      }
    }
    .wave-bot{
      @include m-wave-bot;
    }
  }
  .maskWrap{
    z-index: 4;
    position: relative;
    overflow: hidden;
    margin-top: -307px;
    @include breakpoint(xlarge down){
      margin-top: -387px;
    }
    @include breakpoint(medium down){
      margin-top: -426px;
    }
    .mask-debug{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      /*z-index: 9;*/
      @include breakpoint(xlarge down){
        img{
          max-width: initial;
          height: 635px;
        }
      }
      @include breakpoint(medium down){
        @include flex-align(center, middle);
        img{
          height: 450px;
        }
      }
    }
    .pic{
      position: relative;
      height: calc(var(--vh, 1vh) * 100);
      mask: url(../assets/mask.svg) 0 0 no-repeat;
      img{@include imgfit;}
      /*img{
        position: absolute;
        top: 0;
        left: 0;
        width: 1577px;
        max-width: initial;
      }*/
    }
    .title-area{
      position: absolute;
      right: 14%;
      bottom: 20%;
      text-align: right;
      .en{
        font-family: $en-family;
        font-weight: 600;
        font-style: italic;
        font-size: 129px;
        letter-spacing: 5px;
        color: #fff;
        margin-bottom: 20px;
        @include breakpoint(xlarge down){
          font-size: 86px;
          letter-spacing: 3px;
          margin-bottom: 13px;
        }
        @include breakpoint(medium down){
          font-size: 50px;
          letter-spacing: 2px;
          margin-bottom: 10px;
        }
      }
      .ch{
        font-family: $content-family;
        font-weight: 500;
        font-size: 47px;
        letter-spacing: 20px;
        color: #fff;
        @include breakpoint(xlarge down){
          font-size: 31px;
          letter-spacing: 13px;
        }
        @include breakpoint(medium down){
          font-size: 20px;
          letter-spacing: 8px;
        }
      }
    }
  }
  .mobile-title-area{
    z-index: 14;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 55px;
    text-align: center;
    .en{
      font-family: $en-family;
      font-weight: 600;
      font-style: italic;
      font-size: 54px;
      letter-spacing: 2px;
      color: #fff;
      margin-bottom: 10px;
    }
    .ch{
      font-family: $content-family;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: 8px;
      color: #fff;
    }
  }
</style>