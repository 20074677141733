<template>
  <div class="aboutWrap">
    <div class="article-area">
      <RyderStretch class="head">ABOUT</RyderStretch>

      <div class="title-area">
        <div class="ch">關於易筋</div>
        <div class="en">Stretch Studio</div>
      </div>

      <div class="content-area">
        <div class="deco-1">
          <img src="../assets/about-deco-1.svg" class="show-for-large">
          <img src="../assets/about-deco-1-mobile.svg" class="hide-for-large">
        </div>
        <div class="deco-2">
          <img src="../assets/about-deco-2.svg" class="show-for-large">
          <img src="../assets/about-deco-2-mobile.svg" class="hide-for-large">
        </div>

        <div class="content">「易筋Stretch Studio」創立於2021年，取名「易筋」是因為筋膜放鬆與中華古籍「達摩易筋經」的十二式伸展觀念不謀而合。我們引進歐美最新的筋膜知識與技能，依據全人療法的效能與運作原理，以專業技法釋放筋膜的壓力記憶，重新啟動人體內的正向能量，希望能幫助現代社會高度壓力下的人們，擁有更強健的身、心、靈，享受美好的生活。</div>
      </div>
    </div>

    <div class="wave-top"></div>
    <div class="wave-bot"></div>
  </div>
</template>

<script>
  import RyderStretch from './RyderStretch.vue'

  export default {
    components: {
      RyderStretch,
    },
    props: {},
    mounted() {},
  }
</script>

<style scoped lang="scss">
  .aboutWrap{
    position: relative;
    background-color: rgba(#efefed, .5);
    padding: 170px 0 190px;
    @include breakpoint(xlarge down){
      padding: 130px 0;
    }
    @include breakpoint(medium down){
      padding: 110px 0 140px;
    }
    .article-area{
      max-width: 760px;
      margin: 0 auto;
      text-align: center;
      @include breakpoint(xlarge down){
        max-width: 580px;
      }
    }
    .head{
      @include m-head-en(#009f47);
      margin-bottom: 30px;
      @include breakpoint(xlarge down){
        margin-bottom: 20px;
      }
    }
    .title-area{
      .ch{
        font-family: $content-family;
        font-size: 38px;
        letter-spacing: 25px;
        color: #606060;
        margin-bottom: 20px;
        @include breakpoint(xlarge down){
          font-size: 25px;
          letter-spacing: 17px;
          margin-bottom: 14px;
        }
        @include breakpoint(medium down){
          font-size: 20px;
          letter-spacing: 8px;
        }
      }
      .en{
        font-family: $en-family;
        font-size: 28px;
        letter-spacing: 6px;
        color: #606060;
        @include breakpoint(xlarge down){
          font-size: 18px;
          letter-spacing: 4px;
        }
        @include breakpoint(medium down){
          font-size: 20px;
        }
      }
    }
    .content-area{
      position: relative;
      margin-top: 60px;
      @include breakpoint(xlarge down){
        margin-top: 50px;
      }
      @include breakpoint(medium down){
        padding: 0 40px;
        margin-top: 78px;
      }
      .deco-1{
        position: absolute;
        top: -26px;
        left: -66px;
        @include breakpoint(xlarge down){
          top: -22px;
          left: -42px;
          img{zoom: 0.66;}
        }
        @include breakpoint(medium down){
          top: -32px;
          left: 20px;
          img{zoom: 1;}
        }
      }
      .deco-2{
        position: absolute;
        right: -60px;
        bottom: -4px;
        @include breakpoint(xlarge down){
          right: -40px;
          bottom: -24px;
          img{zoom: 0.66;}
        }
        @include breakpoint(medium down){
          right: 20px;
          bottom: -26px;
          img{zoom: 1;}
        }
      }
      .content{
        font-family: $content-family;
        font-size: 23px;
        letter-spacing: 4px;
        line-height: 2;
        color: #606060;
        text-align: justify;
        @include breakpoint(xlarge down){
          font-size: 18px;
          letter-spacing: 3px;
        }
        @include breakpoint(medium down){
          font-size: 15px;
          letter-spacing: 2px;
          line-height: 1.8;
        }
      }
    }
    .wave-top{
      @include m-wave-top;
    }
    .wave-bot{
      @include m-wave-bot;
    }
  }
</style>