<template>
  <div class="benefitOutWrap">
    <section class="benefitWrap show-for-large">
      <transition name="fade">
        <section class="grid-x align-middle" v-if="now == 0" key="benefit0">
          <div class="article-area cell large-auto">
            <div class="flag">好處<i>01</i></div>
            <div class="title">
              回復<br>
              筋膜靈活度<br>
            </div>
            <div class="content">
              重新平衡筋膜張力整合結構<br>
              減輕身體緊繃，消除疲勞
            </div>
          </div>

          <div class="pic-area cell large-shrink">
            <div class="pic"><img src="../assets/benefit-1.jpg"></div>
            <!-- <div class="deco"><img src="../assets/benefit-deco-1.svg"></div> -->
          </div>
        </section>

        <section class="grid-x align-middle" v-if="now == 1" key="benefit1">
          <div class="article-area cell large-auto">
            <div class="flag">好處<i>02</i></div>
            <div class="title">
              增進<br>
              運動表現<br>
            </div>
            <div class="content">
              幫助養成正向放鬆習慣<br>
              強化筋膜記憶，促進骨骼肌肉協調
            </div>
          </div>

          <div class="pic-area cell large-shrink">
            <div class="pic"><img src="../assets/benefit-2.jpg"></div>
            <!-- <div class="deco"><img src="../assets/benefit-deco-2.svg"></div> -->
          </div>
        </section>

        <section class="grid-x align-middle" v-if="now == 2" key="benefit2">
          <div class="article-area cell large-auto">
            <div class="flag">好處<i>03</i></div>
            <div class="title">
              活化<br>
              全身機能<br>
            </div>
            <div class="content">
              啟動人體自我療癒能力<br>
              從本能、情緒與意念，引導筋膜釋放壓力
            </div>
          </div>

          <div class="pic-area cell large-shrink">
            <div class="pic"><img src="../assets/benefit-3.jpg"></div>
            <!-- <div class="deco"><img src="../assets/benefit-deco-3.svg"></div> -->
          </div>
        </section>
      </transition>
    </section>

    <section class="benefitWrap hide-for-large">
      <section class="grid-x align-middle">
        <div class="article-area cell large-auto">
          <div class="flag">好處<i>01</i></div>
          <div class="title">
            回復<br>
            筋膜靈活度<br>
          </div>
          <div class="content">
            重新平衡筋膜張力整合結構<br>
            減輕身體緊繃，消除疲勞
          </div>
        </div>

        <div class="pic-area cell large-shrink">
          <div class="pic"><img src="../assets/benefit-1.jpg"></div>
          <!-- <div class="deco"><img src="../assets/benefit-deco-1.svg"></div> -->
        </div>
      </section>

      <section class="grid-x align-middle">
        <div class="article-area cell large-auto">
          <div class="flag">好處<i>02</i></div>
          <div class="title">
            增進<br>
            運動表現<br>
          </div>
          <div class="content">
            幫助養成正向放鬆習慣<br>
            強化筋膜記憶，促進骨骼肌肉協調
          </div>
        </div>

        <div class="pic-area cell large-shrink">
          <div class="pic"><img src="../assets/benefit-2.jpg"></div>
          <!-- <div class="deco"><img src="../assets/benefit-deco-2.svg"></div> -->
        </div>
      </section>

      <section class="grid-x align-middle">
        <div class="article-area cell large-auto">
          <div class="flag">好處<i>03</i></div>
          <div class="title">
            活化<br>
            全身機能<br>
          </div>
          <div class="content">
            啟動人體自我療癒能力<br>
            從本能、情緒與意念，引導筋膜釋放壓力
          </div>
        </div>

        <div class="pic-area cell large-shrink">
          <div class="pic"><img src="../assets/benefit-3.jpg"></div>
          <!-- <div class="deco"><img src="../assets/benefit-deco-3.svg"></div> -->
        </div>
      </section>
    </section>
  </div>
</template>

<script>
  import vhCheck from 'vh-check'

  export default {
    props: {},
    data() {
      return {
        now: 0,
      }
    },
    methods: {},
    mounted() {
      var _vhCheck = vhCheck();


      if (device != 'mobile') {
        ScrollTrigger.create({
          trigger: ".benefitWrap",
          toggleActions: "play pause resume reverse", //重覆觸發
          start: "center 50%",
          end: (device != 'mobile') ? "+=300%" : "+=150%",
          pin: ".benefitWrap",
          // markers: true,
          onUpdate: (self) => {
            if (self.progress > 0.6) {
              this.now = 2
            }else if(self.progress > 0.3){
              this.now = 1
            }else{
              this.now = 0
            }
          }
        });
      }


    },
  }
</script>

<style scoped lang="scss">
  .benefitOutWrap{
    position: relative;
  }
  .benefitWrap{
    position: relative;
    // position: sticky;
    // top: 0;
    height: calc(var(--vh, 1vh) * 100);
    @include breakpoint(medium down){
      height: auto;
      padding: 110px 0;
    }
    section{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 0 var(--space);
      @include breakpoint(medium down){
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0%, 0%);
        @include mb(78px);
      }
    }
    .article-area{
      .flag{
        font-family: $ench;
        font-size: 30px;
        letter-spacing: 6px;
        color: #009f47;
        margin-bottom: 14px;
        @include breakpoint(xlarge down){
          font-size: 19px;
          letter-spacing: 4px;
        }
        @include breakpoint(medium down){
          font-size: 13px;
          letter-spacing: 2px;
          margin-bottom: 9px;
        }
        i{
          font-style: italic;
          letter-spacing: 0;
          margin-left: 4px;
          @include breakpoint(medium down){
            margin-left: 3px;
          }
        }
      }
      .title{
        font-family: $content-family;
        font-weight: 300;
        font-size: 129px;
        letter-spacing: 10px;
        line-height: 1.2;
        color: #606060;
        margin-bottom: 50px;
        @include breakpoint(xlarge down){
          font-size: 86px;
          letter-spacing: 6px;
        }
        @include breakpoint(medium down){
          font-size: 50px;
          letter-spacing: 4px;
          margin-bottom: 20px;
        }
      }
      .content{
        font-family: $content-family;
        font-size: 26px;
        letter-spacing: 6px;
        line-height: 2;
        color: #606060;
        @include breakpoint(xlarge down){
          font-size: 22px;
          letter-spacing: 4px;
        }
        @include breakpoint(medium down){
          font-size: 17px;
          letter-spacing: 3px;
        }
      }
    }
    .pic-area{
      position: relative;
      max-width: 840px;
      @include breakpoint(medium down){
        margin-top: 30px;
      }
      .pic{
        @include breakpoint(xlarge down){
          max-width: 560px;
        }
      }
      .deco{
        position: absolute;
        left: -104px;
        bottom: -70px;
        @include breakpoint(xlarge down){
          zoom: 0.66;
        }
        @include breakpoint(medium down){
          zoom: 0.38;
          left: 52px;
          bottom: -60px;
        }
      }
    }
  }
</style>