<template>
  <div class="serviceWrap">
    <div class="deco-1 show-for-large"><img src="../assets/service-deco-1.svg"></div>
    <div class="deco-2 show-for-large"><img src="../assets/service-deco-2.svg"></div>

    <div class="head-area">
      <RyderStretch :p="{
        transformOrigin: 'right',
      }" :m="{
        transformOrigin: '50% 50%',
      }" class="en">SERVICE</RyderStretch>
      <div class="ch">服務項目</div>
    </div>

    <div class="service-container">
      <ul class="serviceList">
        <li>
          <div class="mobile-more hide-for-large"><img src="../assets/mobile-service-more.svg"></div>

          <!-- <div class="pic-area">
            <div class="pic"><img src="../assets/service-1.jpg"></div>
          </div> -->

          <div class="video-container">
            <div class="video-area">
              <video muted autoplay loop playsinline src="../assets/fascial.mp4"></video>
            </div>
          </div>

          <div class="article-area">
            <div class="title-area">
              <div class="en">FASCIAL STRETCH</div>
              <div class="ch">筋膜伸展</div>
            </div>

            <div class="content">伸展師將需要伸展的部位，帶到適當的角度，停留同時調整呼吸，達到放鬆的效果，引導正向意念與情緒，恢復筋膜彈性，從多面向改善身體張力結構，消除疲勞，提升整體身體素質，也能增進運動表現。</div>
          </div>
        </li>
        <li>
          <div class="mobile-more hide-for-large"><img src="../assets/mobile-service-more.svg"></div>

          <!-- <div class="pic-area">
            <div class="pic"><img src="../assets/service-2.jpg"></div>
          </div> -->

          <div class="video-container">
            <div class="video-area">
              <video muted autoplay loop playsinline src="../assets/iastm.mp4"></video>
            </div>
          </div>

          <div class="article-area">
            <div class="title-area">
              <div class="en">IASTM</div>
              <div class="ch">筋膜鬆動術</div>
            </div>

            <div class="content">筋膜刀根據人體工學設計，能檢測筋膜張力，快速定位筋膜糾結點。針對結節與帶狀組織，將緊繃與沾黏的筋膜以特殊手法鬆開，在無痛和不出痧的情況下，化解筋膜糾結、增加筋膜滑順度，恢復筋膜健康。</div>
          </div>
        </li>
        <li>
          <div class="mobile-more hide-for-large"><img src="../assets/mobile-service-more.svg"></div>

          <!-- <div class="pic-area">
            <div class="pic"><img src="../assets/service-3.jpg"></div>
          </div> -->

          <div class="video-container">
            <div class="video-area">
              <video muted autoplay loop playsinline src="../assets/pnf.mp4"></video>
            </div>
          </div>

          <div class="article-area">
            <div class="title-area">
              <div class="en">PNF</div>
              <div class="ch">本體感覺促進術</div>
            </div>

            <div class="content">本體感覺促進術能啟動特定部位本體感覺受器的活性，在達到一定的張力後，透過交互的主動與被動運動、收縮與放鬆，提升筋膜的靈敏度，也可增進關節活動度和肌肉柔軟度，加強筋膜放鬆的效果，恢復動作協調能力。</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import RyderStretch from './RyderStretch.vue'

  export default {
    components: {
      RyderStretch,
    },
    props: {},
    data() {
      return {
        hover: false,
      }
    },
    methods: {},
    mounted() {
      $(".serviceList li").on("click", function() {
        $(this).toggleClass("current")

        // if ($(this).hasClass("current")) {
        //   $("video", this).get(0).play()
        // }else{
        //   $("video", this).get(0).pause()
        // }
      }).on("mouseenter", () => {
        this.$store.commit('cursorShow')
      }).on("mouseleave", () => {
        this.$store.commit('cursorHide')
      })
    }
  }
</script>

<style scoped lang="scss">
  .serviceWrap{
    padding: 100px var(--space) 0;
    position: relative;
    @include breakpoint(medium down){
      padding-top: 70px;
    }
    .deco-1{
      position: absolute;
      top: 400px;
      left: 0;
      @include breakpoint(xlarge down){
        top: 317px;
        img{height: 914px;}
      }
    }
    .deco-2{
      position: absolute;
      top: 400px;
      right: 0;
      @include breakpoint(xlarge down){
        top: 317px;
        img{height: 914px;}
      }
    }
    .head-area{
      text-align: right;
      @include breakpoint(medium down){
        text-align: center;
      }
      .en{
        @include m-head-en;
      }
      .ch{
        @include m-head-ch;
        margin-top: 8px;
      }
    }
  }
  .service-container{
    position: relative;
    margin-top: 110px;
    // cursor: url(../assets/cursor.svg), auto;
    @include breakpoint(xlarge down){
      margin-top: 87px;
    }
    @include breakpoint(medium down){
      margin-top: 40px;
      cursor: auto;
    }
  }
  .serviceList{
    position: relative;
    li{
      position: relative;
      overflow: hidden;
      @include mb(25px);
      @include breakpoint(medium down){
        @include mb(12px);
      }
      &.current{
        .mobile-more{
          opacity: 0;
        }
        .pic-area{
          padding-top: 55.97%;
          @include breakpoint(medium down){
            padding-top: 111.37%;
          }
        }
        .video-container{
          padding-top: 55.97%;
          @include breakpoint(medium down){
            padding-top: 111.37%;
          }
          &:before{opacity: 1;}
        }
        .article-area{
          top: 40px;
          @include breakpoint(medium down){
            top: 20px;
          }
          .content{
             opacity: 1;
          }
        }
      }
    }
    .mobile-more{
      z-index: 4;
      position: absolute;
      top: 20px;
      right: 20px;
      transition: all .3s;
    }
    .pic-area{
      padding-top: 29.96%;
      transition: all .5s;
      @include breakpoint(medium down){
        padding-top: 55.69%;
      }
      .pic{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img{@include imgfit;}
      }
    }
    .video-container{
      position: relative;
      padding-top: 29.96%;
      transition: all .5s;
      @include breakpoint(medium down){
        padding-top: 55.69%;
      }
      &:before{
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#000, .5);
        mix-blend-mode: multiply;
        opacity: 0;
        transition: all .3s;
      }
      &:after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #d0cbc2;
        opacity: 0.7;
        mix-blend-mode: multiply;
        // background-color: #A19A8B;
        // opacity: 0.9;
      }
      .video-area{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        video{
          @include imgfit;
          width: 101%;
        }
      }
    }
    .article-area{
      z-index: 4;
      position: absolute;
      top: 330px;
      left: 40px;
      max-width: 600px;
      transition: all .5s;
      @include breakpoint(xlarge down){
        top: 210px;
        max-width: 480px;
      }
      @include breakpoint(medium down){
        top: 99px;
        left: 20px;
        right: 20px;
      }
      .title-area{
        .en{
          font-family: $en-family;
          font-weight: 300;
          font-style: italic;
          font-size: 32px;
          letter-spacing: 1px;
          color: #fff;
          margin-bottom: 10px;
          @include breakpoint(xlarge down){
            font-size: 19px;
          }
          @include breakpoint(medium down){
            font-size: 16px;
            letter-spacing: 0;
          }
        }
        .ch{
          font-family: $content-family;
          font-size: 61px;
          letter-spacing: 4px;
          color: #fff;
          @include breakpoint(xlarge down){
            font-size: 41px;
            letter-spacing: 3px;
          }
          @include breakpoint(medium down){
            font-size: 36px;
            letter-spacing: 2px;
          }
        }
      }
      .content{
        font-family: $content-family;
        font-size: 25px;
        letter-spacing: 4px;
        line-height: 1.8;
        color: #fff;
        text-align: justify;
        margin-top: 50px;
        opacity: 0;
        transition: all .5s;
        @include breakpoint(xlarge down){
          font-size: 20px;
          letter-spacing: 3px;
          margin-top: 30px;
        }
        @include breakpoint(medium down){
          font-size: 15px;
          letter-spacing: 2px;
          margin-top: 20px;
        }
      }
    }
  }
</style>