<template>
  <div class="stretchWrap">
    <ul class="list">
      <li><img src="../assets/loading-stretch.svg"></li>
      <li><img src="../assets/loading-stretch.svg"></li>
      <li><img src="../assets/loading-stretch.svg"></li>
      <li><img src="../assets/loading-stretch.svg"></li>
      <li><img src="../assets/loading-stretch.svg"></li>
      <li><img src="../assets/loading-stretch.svg"></li>
    </ul>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {}
    },
    methods: {},
    mounted() {},
  }
</script>

<style scoped lang="scss">
  .stretchWrap{
    .list{
      display: inline-flex;
      transform: translateX(-100px);
      animation: stretch-move 10s infinite linear;
      li{
        padding-right: 20px;
        img{max-width: initial;}
        @include breakpoint(xlarge down){
          img{height: 90px;}
        }
        @include breakpoint(medium down){
          img{height: 70px;}
        }
        &:nth-child(odd){
          transform: scaleX(1.5);
          animation: stretch-shrink 1.4s infinite alternate ease-in-out;
        }
        &:nth-child(even){
          transform: scaleX(0.5);
          animation: stretch-grow 1.4s infinite alternate ease-in-out;
        }
      }
    }
  }
  @keyframes stretch-move{
    0%{
      transform: translateX(-100px);
    }
    100%{
      transform: translateX(-1400px);
    }
  }
  @keyframes stretch-grow{
    0%{
      transform: scaleX(0.5);
    }
    100%{
      transform: scaleX(1.5);
    }
  }
  @keyframes stretch-shrink{
    0%{
      transform: scaleX(1.5);
    }
    100%{
      transform: scaleX(0.5);
    }
  }
</style>