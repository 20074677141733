<template>
  <div class="reservationWrap">
    <div class="reservation-marqueeWrap">
      <ul class="marquee">
        <li><img src="../assets/reservation-marquee.svg"></li>
      </ul>

      <ul class="marquee">
        <li><img src="../assets/reservation-marquee.svg"></li>
      </ul>

      <ul class="marquee">
        <li><img src="../assets/reservation-marquee.svg"></li>
      </ul>
    </div>

    <div class="head-area">
      <RyderStretch class="en">RESERVATION</RyderStretch>
      <div class="ch">立即預約</div>
    </div>

   <div class="innerWrap">
      <div class="lesson">
        <img src="../assets/lesson.svg" class="show-for-xxlarge">
        <img src="../assets/lesson-laptop.svg" class="hide-for-xxlarge show-for-large">
        <img src="../assets/lesson-mobile.svg" class="hide-for-large">
      </div>

     <ul class="article-area">
       <li class="grid-x">
         <div class="num cell shrink">01</div>
         <div class="content cell auto">包含一般性全身筋膜伸展及放鬆，可以幫助釋放壓力，活化身心機能與消除疲勞</div>
       </li>
       <li class="grid-x">
         <div class="num cell shrink">02</div>
         <div class="content cell auto">若有筋膜損傷或失衡，可與伸展師討論，加價延長服務時間，或安排系列課程</div>
       </li>
       <li class="grid-x">
         <div class="num cell shrink">03</div>
         <div class="content cell auto">為確保服務品質，採全預約方式，若您現場walk-in，可能需要等待或另排時間</div>
       </li>
     </ul>

     <div class="reservation-now-area-wrap hide-for-large">
       <div class="reservation-now-area"><a href="https://page.line.me/729illup?openQrModal=true" target="_blank">
         <div class="now"><img src="../assets/btn-now.svg"></div>
         <div class="rotate-text">
           <svg xmlns="http://www.w3.org/2000/svg" width="109" height="100.99" viewBox="0 0 109 100.99">
             <defs>
               <path id="path-4" d="M73.69-4.28c19.15,6.75,28.71,27.06,24.21,50-6.1,31.2-33.29,49.27-68.15,45.49C4.61,88.38-9,73.16-9,48-9.46,3.33,34.92-17.84,73.69-4.28ZM73.69-4.28c19.15,6.75,28.71,27.06,24.21,50-6.1,31.2-33.29,49.27-68.15,45.49C4.61,88.38-9,73.16-9,48-9.46,3.33,34.92-17.84,73.69-4.28Z" transform="translate(9.5 8.84)" style="fill: none;stroke: #000;stroke-miterlimit: 10" />
             </defs>

             <text>
               <textPath class="rydertext" xlink:href="#path-4">RESERVE NOW!</textPath>
             </text>
           </svg>
         </div>
       </a></div>
     </div>

     <ul class="info-area grid-x align-center">
       <li class="cell large-shrink">
         <div class="head">營業時間/地址</div>
         <div class="content"><a href="https://goo.gl/maps/h4d9JEMb22u8WfpD6" target="_blank">
          週ㄧ~週日 10:00~22:00<br>
          台中市南屯區河南路四段261號<img src="../assets/map-link.svg">
         </a></div>
       </li>
       <li class="cell large-shrink">
         <div class="head">預約方式</div>
         <nav class="shareList">
           <a href="tel:+886422520558"><img src="../assets/reservation-icon-1.svg"></a>
           <a href="https://page.line.me/729illup?openQrModal=true" target="_blank"><img src="../assets/reservation-icon-2.svg"></a>
           <a href="https://www.facebook.com/EasingStretchStudio/" target="_blank"><img src="../assets/reservation-icon-3.svg"></a>
           <a href="https://www.instagram.com/easing.ss/" target="_blank"><img src="../assets/reservation-icon-4.svg"></a>
           <a href="https://goo.gl/maps/h4d9JEMb22u8WfpD6" target="_blank"><img src="../assets/reservation-icon-5.svg"></a>
           <a href="https://ezpretty.cc/sjP2Q" target="_blank"><img src="../assets/qrcode.png"></a>
         </nav>
       </li>
     </ul>
   </div>

    <div class="wave-top"></div>
    <div class="wave-bot"></div>
  </div>
</template>

<script>
  import RyderStretch from './RyderStretch.vue'
  import RyderMarquee from '../assets/js/marquee.js'

  export default {
    components: {
      RyderStretch
    },
    props: {},
    data() {
      return {}
    },
    methods: {},
    mounted() {
      $(".reservation-marqueeWrap .marquee").each(function (i, el) {
        var $copy = $(el).contents().clone()
        $(el).append($copy)

        var ryderMarquee = new RyderMarquee(el, i).create()
      })




      gsap.to(".reservation-now-area-wrap .rydertext", {
        duration: 12,
        repeat: -1,
        attr: {
          startOffset: "50%"
        },
        ease: "none",
      })
    }
  }
</script>

<style scoped lang="scss">
  .reservationWrap{
    z-index: 1;
    position: relative;
    margin-top: 395px;
    background-color: rgba(#efefed, .5);
    padding-bottom: 200px;
    @include breakpoint(xlarge down){
      margin-top: 290px;
      padding-bottom: 140px;
    }
    @include breakpoint(medium down){
      margin-top: 175px;
      padding-bottom: 70px;
    }
    /*.head-area{
      z-index: 1;
      position: relative;
      text-align: center;
      .ch{
        font-family: $content-family;
        font-weight: 900;
        font-size: 80px;
        letter-spacing: 15px;
        color: #009f47;
      }
      .en{
        margin-top: -11px;
      }
    }*/
    .head-area{
      padding: 0 var(--space);
      text-align: center;
      position: relative;
      top: -110px;
      z-index: 4;
      @include breakpoint(xlarge down){
        top: -84px;
      }
      @include breakpoint(medium down){
        top: -55px;
      }
      .en{
        @include m-head-en;
      }
      .ch{
        @include m-head-ch;
        margin-top: 8px;
      }
    }
    .wave-top{
      @include m-wave-top;
    }
    .wave-bot{
      @include m-wave-bot;
    }
  }
  .reservation-marqueeWrap{
    z-index: -1;
    position: absolute;
    top: 250px;
    left: 0;
    width: 100%;
    overflow: hidden;
    opacity: .05;
    @include breakpoint(xlarge down){
      top: 205px;
    }
    @include breakpoint(medium down){
      top: 147px;
      bottom: auto;
      height: 370px;
    }
    .marquee{
      display: inline-flex;
      @include mb(128px);
      @include breakpoint(xlarge down){
        @include mb(80px);
      }
      @include breakpoint(medium down){
        @include mb(78px);
      }
      li{
        padding-right: 44px;
        img{max-width: initial;}
        @include breakpoint(xlarge down){
          img{height: 70px;}
        }
        @include breakpoint(medium down){
          padding-right: 20px;
          img{height: 58px;}
        }
      }
    }
  }
  .innerWrap{
    max-width: 1500px;
    margin: 0 auto;
    @include breakpoint(xlarge down){
      max-width: 1100px;
    }
    @include breakpoint(medium down){
      padding: 0 20px;
    }
    .lesson{
      text-align: center;
      margin-bottom: 94px;
      @include breakpoint(medium down){
        margin-bottom: 70px;
      }
    }
    .article-area{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0 113px;
      @include breakpoint(xlarge down){
        grid-gap: 0 70px;
      }
      @include breakpoint(medium down){
        grid-template-columns: 1fr;
        grid-gap: 40px 0;
      }
      li{}
      .num{
        font-family: $en-family;
        font-weight: 700;
        font-size: 29px;
        letter-spacing: 1px;
        color: #009f47;
        writing-mode: vertical-rl;
        margin-right: 28px;
        position: relative;
        top: 11px;
        @include breakpoint(xlarge down){
          font-size: 20px;
        }
        @include breakpoint(medium down){
          font-size: 17px;
          margin-right: 16px;
        }
        &:after{
          content: '';
          position: absolute;
          top: 55px;
          left: 15px;
          bottom: 19px;
          width: 2px;
          background-color: #009f47;
          @include breakpoint(xlarge down){
            top: 40px;
            left: 12px;
            bottom: 18px;
          }
          @include breakpoint(medium down){
            top: 34px;
            left: 9px;
          }
        }
      }
      .content{
        font-family: $content-family;
        font-size: 23px;
        letter-spacing: 5px;
        line-height: 2;
        color: #606060;
        @include breakpoint(xlarge down){
          font-size: 18px;
          letter-spacing: 3px;
        }
      }
    }
    .reservation-now-area-wrap{
      text-align: right;
      padding-right: 20px;
      margin-top: 40px;
    }
    .reservation-now-area{
      zoom: 0.65;
      position: relative;
      display: inline-block;
      .now{}
      .rotate-text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 1px 0 0 0;
        svg{
          overflow: visible;
          .rydertext{
            font-family: $en-family;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 2px;
            fill: #969696;
          }
        }
      }
    }
    .info-area{
      margin-top: 100px;
      @include breakpoint(medium down){
        margin-top: 60px;
      }
      li{
        text-align: center;
        @include mr(250px);
        @include breakpoint(medium down){
          @include mr(0);
          @include mb(40px);
        }
      }
      .head{
        font-family: $content-family;
        font-size: 24px;
        letter-spacing: 2px;
        color: #fff;
        background-color: #009f47;
        border-radius: 20px;
        padding: 10px 14px;
        display: inline-block;
        @include breakpoint(xlarge down){
          font-size: 19px;
        }
      }
      .content{
        font-family: $content-family;
        font-size: 23px;
        letter-spacing: 5px;
        line-height: 2;
        color: #606060;
        margin-top: 20px;
        @include breakpoint(xlarge down){
          font-size: 18px;
          letter-spacing: 3px;
        }
        img{
          margin: 0 0 5px 5px;
        }
      }
      .shareList{
        margin-top: 30px;
        a{
          @include basic-hover;
          @include mr(20px);
          @include breakpoint(xlarge down){
            img{zoom: 0.8;}
          }
        }
      }
    }
  }
</style>