<template>
  <div id="app">
    <RyderCursor></RyderCursor>
    <Loading></Loading>
    <Header></Header>
    <Home></Home>
    <Footer></Footer>
  </div>
</template>

<script>
  import RyderCursor from './components/Cursor.vue'
  import Loading from './components/Loading.vue'
  import Home from './views/Home.vue'
  import Header from './components/Header.vue'
  import Footer from './components/Footer.vue'

  export default {
    components: {
      RyderCursor,
      Loading,
      Home,
      Header,
      Footer,
    },
    data() {
      return {}
    },
    metaInfo() {
      return {
        title: '易筋 STRETCH STUDIO',
        // titleTemplate: '%s | vue-meta Example App',
        // htmlAttrs: {
          // lang: 'en-US'
        // },
        meta: [{
          charset: 'utf-8'
        }, {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1'
        }, {
          vmid: 'description',
          name: 'description',
          content: '長期鑽研筋膜伸展相關知識的我們，有感現代人健康狀況日益衰退且缺乏筋膜專業知識，回想其與古籍「達摩易筋經」的十二式伸展觀念不謀而合，遂與醫療專業人員合作自國外引進此技術，於2021年創立筋膜伸展工作室，命名為「易筋Stretch Studio」，易筋的所有筋膜伸展師皆具相關科系大學以上學歷，並經醫療專業人員親授與解剖學課程雙重訓練，旨在提供國內最專業筋膜伸展服務。'
        }],
      }
    },
    mounted() {}
  }
</script>

<style lang="scss">
  #app{
    // @include disableSelect;
    overflow-x: hidden;
  }
</style>
